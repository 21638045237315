'use strict'; // Since we don't import or require any of our files at the moment in the modules that use them
// I need to create this shim file so that the files will be picked up by webpack.
// This is tech debt that will need to be worked
// Import missing AngularJS libraries

import 'angular-messages'; // Import every module

import './app/common/authentication/authentication-module';
import './app/common/communication/communication-module';
import './app/common/dialogs/dialogs-module';
import './app/common/directives/directives-module';
import './app/common/navigation/navigation-module';
import './app/common/notification/notification-module';
import './app/dashboard/dashboard-module';
import './app/deliveries/deliveries-module';
import './app/deliveries/deliveryDetail-module';
import './app/makelist/makeList-module';
import './app/destinations/destinationDetail-module';
import './app/destinations/destinations-module';
import './app/login/login-module';
import './app/orders/orderDetail-module';
import './app/orders/orders-module';
import './app/settings/agencydetail/agencyDetail-module';
import './app/settings/invoicedetail/invoice-module';
import './app/settings/integrationsdetail/integrations-module';
import './app/settings/settings-module';
import './app/settings/userdetail/userdetail-module';
import './app/settings/destinationdetail/destinationdetail-module';
import './app/settings/xferclientdetail/xferclientDetail-module';
import './app/settings/defaultmediaprefersdetail/defaultmediaprefersdetail-module';
import './app/spots/spotDetail-module';
import './app/spots/spots-module';
import './app/submit/submit-module';
import './app/view/view-module';
import './app/view/viewDetail-module';
import './app/aspera/aspera-module'; // Import every custom module and JS file

import './app/settings/agencydetail/agencies-controller';
import './app/settings/agencydetail/advertiserDialog-controller';
import './app/settings/agencydetail/agenciesSearch-controller';
import './app/settings/agencydetail/agencyDetail-controller';
import './app/settings/agencydetail/agencyDetailList-controller';
import './app/settings/settings-controller';
import './app/settings/userdetail/users-controller';
import './app/settings/userdetail/UserDetailList-controller';
import './app/settings/userdetail/userSearch-controller';
import './app/settings/userdetail/userdetail-controller';
import './app/settings/destinationdetail/destinations-controller';
import './app/settings/destinationdetail/destinationsSearch-controller';
import './app/settings/xferclientdetail/xferclients-controller';
import './app/settings/xferclientdetail/xferclientDetailList-controller';
import './app/settings/xferclientdetail/xferclientDetail-controller';
import './app/settings/xferclientdetail/xferclientsSearch-controller';
import './app/settings/invoicedetail/invoicesSearch-controller';
import './app/settings/defaultmediaprefersdetail/defaultmediaprefersdetail-controller';
import './app/submit/liteDialog-controller';
import './app/submit/searchDialog-controller';
import './app/submit/spotDurationDialog-controller';
import './app/destinations/newDestinationListDialog-controller';
import './app/common/directives/updateRepeaterSize-directive';
import './app/common/directives/statusBar-directive';
import './app/common/directives/spotPlayer-directive';
import './app/common/directives/numericFilter-directive';
import './app/common/directives/dashlessFilter-directive';
import './app/common/directives/mblDblclick-directive';
import './app/common/directives/toggleSearch-directive';
import './app/common/directives/sizeToHeader-directive';
import './app/common/directives/timeDurationFilter-directive';
import './app/common/directives/fileUpload-directive';
import './app/common/directives/childPruner-directive';
import './app/common/directives/nullOrFalse-filter';
import './app/common/notification/notification-service';
import './app/common/navigation/standardNavbar-controller';
import './app/common/navigation/loginNavbar-controller';
import './app/common/navigation/editAccountDialog-controller';
import './app/common/navigation/subNavbar-controller';
import './app/common/navigation/changePasswordDialog-controller';
import './app/common/dialogs/systemEventsDialog-controller';
import './app/common/dialogs/spotRejectionDialog-controller';
import './app/common/communication/interceptors/notification-interceptor';
import './app/common/communication/enum-service';
import './app/common/communication/resources/asset-resource';
import './app/common/communication/resources/audiochannel-resource-factory';
import './app/common/communication/resources/securityAccount-resource-factory';
import './app/common/communication/resources/receiver-resource-factory';
import './app/common/communication/resources/securityrolepermcontext-resource-factory';
import './app/common/communication/resources/prodsvcsvendor-resource-factory';
import './app/common/communication/resources/guide-resource';
import './app/common/communication/resources/destination-list-resource-factory';
import './app/common/communication/resources/provider-resource-factory';
import './app/common/communication/resources/enum-resource-factory';
import './app/common/communication/resources/securityrole-resource-factory';
import './app/common/communication/resources/user-resource';
import './app/common/communication/resources/xfer-client-resource';
import './app/common/communication/resources/password-resource-factory';
import './app/common/communication/resources/tapevendor-resource-factory';
import './app/common/communication/resources/destination-type-resource-factory';
import './app/common/communication/resources/qcvendor-resource-factory';
import './app/common/communication/resources/authentication-resource';
import './app/common/communication/resources/branding-resource';
import './app/common/communication/resources/litedelivery-resource-factory';
import './app/common/communication/resources/orderHoldReasonType-resource';
import './app/common/communication/resources/delivery-resource-factory';
import './app/common/communication/resources/order-resource';
import './app/common/communication/resources/market-resource-factory';
import './app/common/communication/resources/mediaformat-resource-factory';
import './app/common/communication/resources/systemevents-resource-factory';
import './app/common/communication/resources/destination-resource-factory';
import './app/common/communication/resources/specialOrderType-resource';
import './app/common/communication/resources/termsAndConditions-resource';
import './app/common/communication/resources/deliveryConfirmation-resource';
import './app/common/communication/resources/videoformatprofile-resource';
import './app/common/communication/resources/aspera-resource-factory';
import './app/common/communication/resources/welcomeMessage-resource';
import './app/common/communication/status-service';
import './app/common/communication/submitManager-service';
import './app/common/communication/socket-service';
import './app/common/communication/restrictFile-service';
import './app/view/view-controller';
import './app/view/viewSearch-controller.ts';
import './app/view/viewGrid-controller';
import './app/view/metadataReportDialog-controller';
import './app/view/viewDetailList-controller';
import './app/view/thumbnailDialog-controller';
import './app/view/viewDetail-controller';
import './app/orders/orderSearch-controller.ts';
import './app/orders/showNotesDialog-controller';
import './app/orders/orderDetail-controller.ts';
import './app/orders/orderGroupDialog-controller';
import './app/orders/dialogs/ecnDialog-controller';
import './app/orders/dialogs/deliveryPriorityDialog-controller';
import './app/orders/dialogs/removeOrderGroupDialog-controller';
import './app/orders/dialogs/orderImportErrorDialog-controller';
import './app/orders/dialogs/optimizeDialog-controller';
import './app/orders/dialogs/quickAddSpotDialog-controller';
import './app/orders/dialogs/metadataDialog-controller';
import './app/orders/dialogs/vastXmlDialog-controller.ts';
import './app/orders/orders-controller';
import './app/login/login-controller';
import './app/login/eula-controller';
import './app/login/browser-controller';
import './app/login/reset-controller';
import './app/login/register-controller';
import './app/spots/spotDetail-controller.ts';
import './app/spots/spotSearch-controller.ts';
import './app/spots/spotDetailList-controller';
import './app/spots/spots-controller';
import './app/spots/overrideIngestDialog-controller';
import './app/spots/spotStatisticsDialog-controller';
import './app/spots/friendsDialog-controller';
import './app/spots/adIdMetadataDialog-controller';
import './app/spots/copySpotProxyDialog-controller';
import './app/makelist/makeList-controller';
import './app/makelist/addDestinationDialog-controller';
import './app/makelist/orderConfirmationDialog-controller';
import './app/makelist/quickAddSpotsDialog-controller.ts';
import './app/aspera/aspera-service';
import './app/common/communication/migrationManager-service';